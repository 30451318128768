//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import initSqlJs from 'sql.js'
// import axios from 'axios'
import page from "page"
import MainLayout from "../layouts/Main.vue"
import highlighter from "../api/highlighter"
import { date } from 'quasar'
import SnsDialog from '../components/SnsDialog.vue'

export default {
  name: 'BookShelf',
  data: function() {
    return {
      isSharing: false,
      snsContent: {},
      wasmFile: "./sql-wasm.wasm",
      databaseFile: "./KoboReader.sqlite",
      db: null,
      shelf: {
        isFetching: true,
        data: {
          columns: [],
          values: []
        }
      },
      keyword: ""
    }
  },
  computed: {
    sortedShelf: function() {
      return {
        heading: this.shelf.data.columns.length ? this.shelf.data.columns[0] : "",
        items: this.shelf.data.values.map( (x, index) => {
          return {
            index: index,
            // name: x[this.getColumnIndex('text')],
            title: x[this.getColumnIndex('book_title')],
            id: x[this.getColumnIndex('book_id')],
            count: x[this.getColumnIndex('count')],
            lastmod: date.formatDate(x[this.getColumnIndex('last_mod')], 'DD/MMM/YYYY hh:mm:ss a')
          }
        }).filter(item => !this.keyword || this.highlight(item.name) !== item.name || this.highlight(item.title) !== item.title)
      }
    }
  },
  methods: {
    share: function(item) {
      this.isSharing = true
      this.snsContent = item
    },
    onCopy: function (e) {
      console.debug(e.text)
      this.$q.notify({
        type: 'positive',
        message: 'Quote copied!',
        caption: 'Ctrl + V to paste.'
      })
    },
    getColumnIndex(name) {
      /*
          trim(bm.Text) as text, 
    b.BookID as book_id,
    b.BookTitle as book_title, 
    bm.DateModified as last_mod
      */
      return this.shelf.data.columns.indexOf(name)
    },
    highlight(content) {
      return highlighter.highlight(this.keyword, content, 'bg-accent text-white text-bold')
    },
    // highlight(content) {
    //     if(!this.keyword) {
    //         return content;
    //     }
    //     try {
    //       return content.replace(new RegExp(escapeRegExp(this.keyword), "gi"), match => {
    //           return '<span class="bg-warning text-weight-bolder">' + match + '</span>';
    //       });
    //     } catch {
    //       return content;
    //     }

    //     function escapeRegExp(content) {
    //       // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
    //       return content.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    //     }
    // },
    sortByHeading: function(a, b) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }
  },
  async mounted() {
    if(!this.$root.$data.kobo) {
      page('/')
      return;
    }

    // const SQL = await initSqlJs({
    //   // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
    //   // You can omit locateFile completely when running in node
    //   locateFile: () => `./${this.wasmFile}`
    // })
    // // var filebuffer = fs.readFileSync(this.databaseFile);
    // const response = await axios.get(this.databaseFile, {responseType: 'arraybuffer'});
    // this.db = new SQL.Database(new Uint8Array(response.data));
    this.db = this.$root.$data.kobo

    // const query = "SELECT `BookId`, `BookTitle` FROM `content` where `bookTitle` is not NULL group by `BookId`"
    // const query = "SELECT `name`, `sql` FROM `sqlite_master`  WHERE type='table';"
    // let query = "SELECT `BookTitle`, `BookId` FROM `content` where `bookTitle` is not NULL and `bookTitle` != '' group by `BookTitle`"
    const query=`select 
    count(bm.DateModified) as count,
    bm.DateModified as last_mod,
    b.BookID as book_id,
    b.BookTitle as book_title
from Bookmark as bm, Content as b
where bm.ContentID = b.ContentID
and bm.Text is not NULL
and b.BookTitle is not null
group by b.BookTitle
order by 
    bm.DateModified desc, 
    b.BookTitle desc
    `
    const res = this.db.exec(query);
    if(res.length) {
      this.shelf.data = res[0]
      this.shelf.isFetching = false
    }
    console.debug(res);
  },
  components: {
    MainLayout,
    SnsDialog,
  },
}
